<template>
    <div class="display-block">
        <div class="widget">
            <h2 style="margin-top: 0px;">Team</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Team'
}
</script>